import React, { useEffect, useState, useContext } from "react"
import { API, button, graphqlOperation } from 'aws-amplify'
import SingleEnrollmentSummary from "./single-enrollment-summary"
import UserHistoricalEnrollment from "./user-historical-enrollment"
import { UserContext } from "../provider/usercontextprovider"
import Select from 'react-select'
import { LoadMaskContext } from "../provider/loadmaskprovider";
import { addUserToGroup, removeUserFromGroup } from "../services/admin"
import { InternationalizationProvider } from "../provider/i18nprovider"
import * as inputStyles from './search-input.module.css'
import ReactModal from 'react-modal';
import * as modalStyles from './modalstyles.module.css'
import * as buttonStyles from './button.module.css'
import * as formElementStyles from '../styles/form-elements.module.css'
import * as userDetailsOverviewStyles from './user-details-overview.module.css'

const searchQuestionnairesFlat = `
    query SearchQuestionnaires(
        $filter: SearchableQuestionnaireFilterInput
        $sort: SearchableQuestionnaireSortInput
        $limit: Int
        $nextToken: String
      ) {
        searchQuestionnaires(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            reporters
            owner
            screeningDate
            updatedOn
            resultCode
            result
            tenant
          }
          nextToken
          total
        }
      }
    `;
const getTenantResultTranslations = /* GraphQL */ `
    query getTenantResultTranslations($tenant: ID!, $language: String!) {
        getCodeTranslations(language: $language, tenant: $tenant){
            analyticResults{
                id
                alias
                answer
            }
        }
    }
`

const listUserSubscriptions = /* GraphQL */ `
    query listUserSubscriptionByUserIdAndTenantId($userId: ID, $tenantId: ModelIDKeyConditionInput) {
        listUserSubscriptionByUserIdAndTenantId(userId: $userId, tenantId: $tenantId) {
            items {
                userId
                updatedAt
                tenantId
                subscribedTo
                resultCode
                createdAt
            }
        }
    }

`
const listUserTenants = `query getVerifiedUserTenantsByTenant(
    $tenantId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getVerifiedUserTenantsByTenant(tenantId:$tenantId, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      tenantId
      tenantRole
      tenantAdminGroup
      tenantProvidedUserId
      firstName
      lastName
    }
    nextToken
  }
}`

const deleteUserSubscription = /* GraphQL */ `
    mutation deleteUserSubscription($userId: ID!, $tenantId: ID!, $resultCode: String!, $subscribedTo: String!) {
        deleteUserSubscription(input: {resultCode: $resultCode, subscribedTo: $subscribedTo, tenantId: $tenantId, userId: $userId}) {
            userId
        }
    }
`

const createUserSubscription = /* GraphQL */ `
    mutation createUserSubscription($userId: ID!, $tenantId: ID!, $resultCode: String!, $subscribedTo: String!, $tenantAdminGroup: String!) {
        createUserSubscription(input: {resultCode: $resultCode, subscribedTo: $subscribedTo, tenantId: $tenantId, userId: $userId, tenantAdminGroup: $tenantAdminGroup}) {
            userId
        }
    }
`

const getUserIdentificationKey = /* GraphQL */ `
    query getUserIdentificationKey($input: ID!) {
        getUserIdentificationKey(owner: $input) {
        qrcode
    }
}`

const updateUserTenantGql = /* GraphQL */ `
    mutation updateUserTenant($input: UpdateUserTenantInput!) {
        updateUserTenant(input: $input){
            tenantAdminGroup
            tenantProvidedUserId
            tenantRole
            userId
            tenantId
        }
    }`

// I added this because if a field is set to undefined, it will switch between
// a controlled or non controlled input
const emptyModalState = {
    selectedRoles:[],
    tenantProvidedUserId: "",
    resultCodes: []
}    

const UserDetailsOverview = (props) => {

    const [screeningHistory, setScreeningHistory] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [userIDKey, setUserIDKey] = useState(null);
    const [isLoading, setIsLoading] = useContext(LoadMaskContext)
    const [currentUserState, setCurrentUserState] = useState({})
    const [modalState, setModalState] = useState(emptyModalState)
    const [currentUserSubs, setCurrentUserSubs] = useState({})
    const [modalIsOpen,setIsOpen] = useState(false);
    useEffect(() => {
        //TODO either the GraphQL needs to return the real name or we need to query the admin API

        async function fetchData() {
            const results = await API.graphql(graphqlOperation(searchQuestionnairesFlat, { filter: { owner: { eq: props.employee.userId } }, limit: 21, nextToken: null, sort: { field:"screeningDate", direction:"desc" } }))
            setScreeningHistory(results.data.searchQuestionnaires.items)
        }
        async function fetchQRCode() {
             let result = await API.graphql(
                graphqlOperation(getUserIdentificationKey, {
                    input: props.employee.userId
                })
            )
            if(result.data.getUserIdentificationKey) {
                setUserIDKey(result.data.getUserIdentificationKey.qrcode)
            }
        }
        fetchQRCode()
        fetchData()
        let selected = []
        const newSelectOptions = props.employee.tenantObj.allowedRoles.map(role => {
            const option = {
                value: role,
                label: role
            };
            if(props.employee.tenantRole.includes(role)){
                selected.push(option)
            }
            return option;
        })
        setSelectOptions(newSelectOptions)
        // Have to set values to something or it will not work
        setCurrentUserState({
            selectedRoles: selected,
            tenantProvidedUserId: props.employee.tenantProvidedUserId ? props.employee.tenantProvidedUserId : ""
        })

    }, [props.employee.userId])

    const updateUserTenant = async () => {
        let result = await API.graphql(
           graphqlOperation(updateUserTenantGql, {
               input: {
                    userId: props.employee.userId, 
                    tenantRole: modalState.selectedRoles ? modalState.selectedRoles.map(option => option.value) : [],
                    tenantProvidedUserId: modalState.tenantProvidedUserId, 
                    tenantId: props.employee.tenantId, 
                    tenantAdminGroup: props.employee.tenantAdminGroup
                }
            })
        )
        return result.data.updateUserTenant 
    }
    const mapRole = (name) =>{
        let roles = [];
        if(name === 'Admin'){
            roles.push(props.employee.tenantObj.tenantAdminGroup)
            roles.push("TenantAdmin")
        }else if(name === 'Manager'){
            roles.push(props.employee.tenantObj.adminGroup)
            roles.push("Manager")
        }
        return roles
    }

    const viewQRCode = (evt) => { 
        let image = new Image();
        image.src = `data:image/jpeg;base64,${userIDKey}`;
    
        var w = window.open("");
        w.document.write(image.outerHTML);
        w.document.close()
        evt.preventDefault()
      }

    const changeRoles = (newOptions,evt) => {
        setModalState({
            ...modalState,
            selectedRoles: newOptions
        })
    }

    const changeSubs = (resultCode) => (newOptions,evt) => {
        const newState = {...modalState}
        if(newOptions === null){
            newOptions = []
        }
        newState.resultCodeSelectedOptions[resultCode] = newOptions
        setModalState(newState)
    }

    const changeTextInput = (evt) => {
        let newModalState = {
            ...modalState,
            [evt.target.name]: evt.target.value
        }
        setModalState(newModalState)
    }

    ReactModal.setAppElement(`#___gatsby`);
    const openModal = async () => {
        setIsOpen(true)
        const resultCodesPromise = API.graphql(
            graphqlOperation(getTenantResultTranslations, { 
                tenant: props.employee.tenantObj.name,
                language: "eng"
            }
        ))

        const userSubsPromise = API.graphql(
            graphqlOperation(listUserSubscriptions, { 
                tenantId: {
                    eq: props.employee.tenantObj.name
                },
                userId: props.employee.userId
            }
        ))

        const tenantUsersPromise = API.graphql(
            graphqlOperation(listUserTenants, { 
                tenantId: props.employee.tenantObj.name
            }
        ))

        let [resultCodes,userSubs,tenantUsers] = await Promise.all([resultCodesPromise,userSubsPromise,tenantUsersPromise])

        const tenantUserOptions = tenantUsers.data.getVerifiedUserTenantsByTenant.items.map(user =>{
            return {
                value: user.userId,
                label: `${user.firstName} ${user.lastName}`,
                tenantProvidedUserId: user.tenantProvidedUserId
            };
        })
        let resultCodeSelectedOptions = {}
        for(let code of resultCodes.data.getCodeTranslations.analyticResults){
            resultCodeSelectedOptions[code.id] = []
        }
         
        for(let sub of userSubs.data.listUserSubscriptionByUserIdAndTenantId.items){
            resultCodeSelectedOptions[sub.resultCode].push({
                value: sub.subscribedTo,
                label: sub.subscribedTo
            })
        }

        setCurrentUserSubs({...resultCodeSelectedOptions})
        setModalState({
            ...currentUserState,
            resultCodes: resultCodes.data.getCodeTranslations.analyticResults,
            resultCodeSelectedOptions: resultCodeSelectedOptions,
            tenantUserOptions: tenantUserOptions
        })
    }

    const closeModal = () => {
        setIsOpen(false)
        setModalState(emptyModalState)
    }

    const modalSave = async () => {
        console.log(isLoading)
        const newSelectedRoles = modalState.selectedRoles ? modalState.selectedRoles : []
        const oldSelectedRoles = currentUserState.selectedRoles ? currentUserState.selectedRoles : []
        setIsLoading(true)
        
        const additions = newSelectedRoles.filter(x => !oldSelectedRoles.includes(x)).map(r => mapRole(r.value)).flat()
        const removals = oldSelectedRoles.filter(x => !newSelectedRoles.includes(x)).map(r => mapRole(r.value)).flat()

        const removalPromises = Promise.all(removals.map(async (r) => {
            await removeUserFromGroup(props.employee.userId,r,props.employee.tenantId)
        }));
        const additionPromises = Promise.all(additions.map(async (r) => {
            await addUserToGroup(props.employee.userId,r,props.employee.tenantId)
        }));

        let subPromises = []
        for (const [key, values] of Object.entries(currentUserSubs)) {
            const oldSubs = values
            const newSubs = modalState.resultCodeSelectedOptions[key]

            const addedSubs = newSubs.filter(x => !oldSubs.includes(x))
            const removedSubs = oldSubs.filter(x => !newSubs.includes(x))

            const addedSubPromises = addedSubs.map(sub => {
                return API.graphql(
                    graphqlOperation(createUserSubscription, {
                        userId: props.employee.userId, 
                        tenantId: props.employee.tenantId, 
                        resultCode: key,
                        subscribedTo: sub.value,
                        tenantAdminGroup: props.employee.tenantAdminGroup
                    })
                )
            })

            const removedSubPromises = removedSubs.map(sub => {
                return API.graphql(
                    graphqlOperation(deleteUserSubscription, {
                        userId: props.employee.userId, 
                        tenantId: props.employee.tenantId, 
                        resultCode: key,
                        subscribedTo: sub.value
                    })
                )
            })
            subPromises.push(...addedSubPromises)
            subPromises.push(...removedSubPromises)
            console.log(subPromises)
        }

        await Promise.all([updateUserTenant(),removalPromises,additionPromises,subPromises])

        setCurrentUserState({...modalState})
        setCurrentUserSubs(modalState.resultCodeSelectedOptions)
        setIsLoading(false)
        setIsOpen(false)
    }

    const formatOptionLabel = ({ value, label, tenantProvidedUserId }) => (
        <div style={{ display: "flex", flexDirection:"column" }}>
          <div>{label}</div>
          <div style={{ color: "#6B7280" }}>
            <small>ID:{tenantProvidedUserId}</small>
            <br/>
            <small>Username:{value}</small>
          </div>
        </div>
    );

    const [userContext, setUserContext] = useContext(UserContext);
    if (userContext.username && userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('Manager')) {
        return (
            <>
                <InternationalizationProvider>
                    <div className={userDetailsOverviewStyles.heading}>
                        <h1>{`${props.employee.firstName} ${props.employee.lastName}`}</h1>
                        <button className={buttonStyles.button} onClick={openModal}>Edit</button>
                    </div>
                    <div className={userDetailsOverviewStyles.content}>
                        <aside style={{ maxWidth: "14rem" }}>
                            <div style={{ marginTop: "1rem" }}>
                                <dt style={{ fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: "500", color: "#6B7280" }}>Username</dt>
                                <dd style={{ marginTop: "0.25rem", color: "#111827" }}>{props.employee.userId}</dd>
                            </div>
                            <div style={{ marginTop: "1rem" }}>
                                <dt style={{ fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: "500", color: "#6B7280" }}>Tenant Provided ID</dt>
                                <dd style={{ marginTop: "0.25rem", color: "#111827" }}>{props.employee.tenantProvidedUserId}</dd>
                            </div>
                            <div style={{ marginTop: "1rem" }}>
                                <dt style={{ fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: "500", color: "#6B7280" }}>User Identification Key</dt>
                                <dd style={{ marginTop: "0.25rem", color: "#111827" }}>
                                    {userIDKey ?
                                        <>
                                            <img style={{ height: "8rem", width: "8rem", marginBottom: "0.25rem" }} 
                                                src={`data:image/jpeg;base64,${userIDKey}`}/>
                                            <button type="button" className={`${buttonStyles.secondaryButton} ${buttonStyles.smallButton}`} onClick={viewQRCode}>View/Print QR Code</button>
                                        </>
                                        :
                                        <div style={{fontStyle: "italic"}}>A User Identification Key has not been created for this user.</div>
                                    }
                                </dd>
                            </div>
                        </aside>
                        <div className={userDetailsOverviewStyles.screenings}>
                            {screeningHistory.filter((e, i) => i === 0).map((historyItem, i) => (
                                <div style={{ marginBottom: "1rem" }}>
                                    <h3 style={{ fontSize: "1.125rem", lineHeight: "1.5rem", fontWeight: "500", color: "#111827", marginBottom: "0" }}>Most Recent Screening</h3>
                                    <SingleEnrollmentSummary key={historyItem.screeningDate} snapshot={historyItem} />
                                </div>
                            ))}
                            <div style={{ fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: "500", color: "#6B7280" }}>Screening History</div>
                            {screeningHistory.length <= 1 ? 
                                <div style={{fontStyle: "italic"}}>There are no historical screening results for this user.</div> : 
                                screeningHistory.filter((e, i) => i !== 0).map((historyItem, i) => (
                                    <UserHistoricalEnrollment key={historyItem.screeningDate} snapshot={historyItem} />
                                ))
                            }
                        </div>
                        <ReactModal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Edit User"
                            className={modalStyles.modal}
                            overlayClassName={modalStyles.overlay}>
                                <div className={modalStyles.modalContent}>
                                    <div className={modalStyles.modalHeader}>
                                        <h2>Edit User</h2>
                                    </div>
                                    <div className={modalStyles.modalBody}>
                                        <label>
                                            Tenant Provided ID
                                            <input onChange={changeTextInput} name="tenantProvidedUserId" style={{margin:"0 0 5px 0", width:"100%"}} value={modalState.tenantProvidedUserId} className={inputStyles.inputcss} type="text"/>
                                        </label>
                                        <label>
                                            Roles
                                            <Select onChange={changeRoles} value={modalState.selectedRoles} options={selectOptions} isMulti/>
                                        </label>
                                        <hr style={{marginTop:'1em'}}/>
                                        <h3>Subscriptions</h3>
                                        {modalState.resultCodes && modalState.resultCodeSelectedOptions && modalState.resultCodes.map(result=>{
                                            return(
                                                <div className={formElementStyles.inputWrapper}>
                                                    <label className={formElementStyles.label}>{result.id} - {result.alias}</label>
                                                    <Select formatOptionLabel={formatOptionLabel} onChange={changeSubs(result.id)} options={modalState.tenantUserOptions} value={modalState.resultCodeSelectedOptions[result.id]} isMulti/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={modalStyles.modalFooter}>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <button className={buttonStyles.secondaryButton} onClick={closeModal}>Close</button>
                                            <button className={buttonStyles.button} onClick={modalSave}>Save</button>
                                        </div>
                                    </div>
                                </div>
                        </ReactModal>
                    </div>
                </InternationalizationProvider>
            </>

        )
    }else{
        return (
            <p>User is not a manager</p>
        )
    }
}

export default UserDetailsOverview
