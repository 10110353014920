import { Auth, API } from 'aws-amplify';

export async function addUserToGroup(username, groupname, tenantId) {
  let apiName = "AdminQueries"
  let path = "/addUserToGroup"
  let myInit = {
    body: {
      username: username,
      groupname: groupname,
      tenantId: tenantId
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}
export async function removeUserFromGroup(username, groupname, tenantId) {
    let apiName = "AdminQueries"
    let path = "/removeUserFromGroup"
    let myInit = {
      body: {
        username: username,
        groupname: groupname,
        tenantId: tenantId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    }
    return await API.post(apiName, path, myInit)
  }
  