import React, { useContext, useEffect } from "react"
import { i18nContext } from "../provider/i18nprovider";

function SingleEnrollmentSummary(props){
    useEffect(() => {
        console.log(props)
    })

    const [i18n, setI18n] = useContext(i18nContext)
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let dtf = new Intl.DateTimeFormat("en", options);

    if (props.snapshot) {
        return (
            <div style={{ marginTop: "0.4rem", display: "flex", flexBasis: "0", flexGrow: "1", borderRadius: "0.5rem", backgroundColor: "#FFFFFF", boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)", border: "1px solid #F3F4F6" }}>
                <div style={{ padding: "1.25rem 1rem", width: "33.3%" }}>
                    <dt style={{ color: "#6B7280", fontSize: "0.875rem" }}>Result</dt>
                    <dd style={{ marginTop: "0.25rem", marginBottom: "0", display: "flex", alignItems: "baseline" }}>
                        <div style={{ display: "flex", alignItems: "baseline", flexWrap: "wrap", fontSize: "1rem", lineHeight: "1.5rem", fontWeight: "400" }}>
                            <strong>{Object.keys(i18n).length > 0 && i18n[props.snapshot.tenant]['eng']['analyticResults'][props.snapshot.resultCode].alias}</strong>&nbsp;({props.snapshot.resultCode})
                        </div>
                    </dd>
                </div>
                <div style={{ padding: "1.25rem 1rem", width: "33.3%" }}>
                    <dt style={{ color: "#6B7280", fontSize: "0.875rem" }}>Reporters</dt>
                    <dd style={{ marginTop: "0.25rem", marginBottom: "0", display: "flex", alignItems: "baseline" }}>
                        <div style={{ display: "flex", alignItems: "baseline", fontSize: "1rem", lineHeight: "1.5rem", fontWeight: "600" }}>
                            <strong>{props.snapshot.reporters.join(', ')}</strong>
                        </div>
                    </dd>
                </div>
                <div style={{ padding: "1.25rem 1rem", width: "33.3%" }}>
                    <dt style={{ color: "#6B7280", fontSize: "0.875rem" }}>Date</dt>
                    <dd style={{ marginTop: "0.25rem", marginBottom: "0", display: "flex", alignItems: "baseline" }}>
                        <div style={{ display: "flex", alignItems: "baseline", fontSize: "1rem", lineHeight: "1.5rem", fontWeight: "600" }}>
                            <strong>{dtf.format(new Date(props.snapshot.screeningDate))}</strong>
                        </div>
                    </dd>
                </div>
            </div>
        )
    } else {
        return (
            <div>This person hasn't been enrolled yet.</div>
        )
    }
}

export default SingleEnrollmentSummary


