import React, {useContext, useEffect} from "react"
import { i18nContext } from "../provider/i18nprovider"

const HistoricalEnrollment = (props) => {
    const [i18n,setI18n] =useContext(i18nContext)

    useEffect(() => {
        console.log(props)
    }, [])

    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let dtf = new Intl.DateTimeFormat("en", options);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem auto 1rem' }}>
                <div>
                    <strong>{Object.keys(i18n).length > 0 && i18n[props.snapshot.tenant]['eng']['analyticResults'][props.snapshot.resultCode].alias}</strong> ({props.snapshot.resultCode})
                </div>
                <div>
                    {dtf.format(new Date(props.snapshot.screeningDate))}
                </div>
            </div>
    Received <strong>"{props.snapshot.result}"</strong> after completing the screening
            <hr style={{margin: '1rem auto 1rem'}}></hr>
            
        </>
    )
}

export default HistoricalEnrollment