import React from "react"
import SEO from "../../components/seo"
import CustomAuth from "../../components/custom-auth"
import UserDetailsOverview from "../../components/user-details-overview"

const UserDetails = ({ location }) => {
  return (
    <CustomAuth>
      <SEO title="User Details" />
      <UserDetailsOverview employee={location.state ? location.state : null} />
    </CustomAuth>
  )
}

export default UserDetails
